import {useForm} from 'react-hook-form';
import {useId} from 'react';
import {Form} from '@common/ui/forms/form';
import {AccountSettingsPanel} from './account-settings-panel';
import {useUpdateAccountDetails} from './basic-info-panel/update-account-details';
import {Button} from '@common/ui/buttons/button';
import {User} from '../../user';
import {Option} from '../../../ui/forms/combobox/combobox';
import {FormSelect} from '@common/ui/forms/select/select';
import {Trans} from '@common/i18n/trans';
import {AccountSettingsId} from '@common/auth/ui/account-settings/account-settings-sidenav';
import {message} from '@common/i18n/message';
import {useTrans} from '@common/i18n/use-trans';

interface Props {
  user: User;
}

export function PreferencesPanel({user}: Props) {
  const formId = useId();
  const {trans} = useTrans();
  const form = useForm<Partial<User>>({
    defaultValues: {
      autostart_playlist_id: user.autostart_playlist_id,
    },
  });
  const updateDetails = useUpdateAccountDetails(form);

  return (
    <AccountSettingsPanel
      id={AccountSettingsId.Preferences}
      title={<Trans message="Preferences" />}
      actions={
        <Button type="submit" variant="flat" color="primary" form={formId} disabled={updateDetails.isPending || !form.formState.isValid}>
          <Trans message="Save" />
        </Button>
      }
    >
      <Form
        form={form}
        onSubmit={newDetails => {
          updateDetails.mutate(newDetails);
        }}
        id={formId}
      >
        <FormSelect
          className="mb-24"
          selectionMode="single"
          name="autostart_playlist_id"
          label={<Trans message="Autoplay playlist on startup" />}
          description={<Trans message={'Select a playlist if you want the queue to automatically start the playlist of your choice when you startup Synerplay'} />}
          showSearchField
          searchPlaceholder={trans(message('Search playlists'))}
        >
          <Option value={''}>
            <Trans message={'No playlist'} />
          </Option>
          {user.playlists.map(p => (
            <Option key={p.id} value={p.id}>
              {p.name}
            </Option>
          ))}
        </FormSelect>
      </Form>
    </AccountSettingsPanel>
  );
}
