import {getFromLocalStorage, setInLocalStorage} from "@common/utils/hooks/local-storage";
import {keepPreviousData, useQuery} from "@tanstack/react-query";
import {apiClient, queryClient} from "../../http/query-client";
import {BootstrapData} from "./bootstrap-data";

const queryKey = ["bootstrapData"];

export function getBootstrapData(): BootstrapData {
  return queryClient.getQueryData(queryKey)!;
}

export function invalidateBootstrapData() {
  queryClient.invalidateQueries({queryKey});
}

export function setBootstrapData(data: string | BootstrapData) {
  queryClient.setQueryData<BootstrapData>(queryKey, typeof data === "string" ? decodeBootstrapData(data) : data);

  setInLocalStorage("be.cachedBootstrapData", typeof data === "string" ? decodeBootstrapData(data) : data);
}

export function mergeBootstrapData(partialData: Partial<BootstrapData>) {
  setBootstrapData({
    ...getBootstrapData(),
    ...partialData,
  });
}

let initialBootstrapData: BootstrapData | undefined;

if (typeof window !== "undefined" && window.bootstrapData) {
  initialBootstrapData = decodeBootstrapData(window.bootstrapData);

  setInLocalStorage("be.cachedBootstrapData", window.bootstrapData);
} else if (getFromLocalStorage("be.cachedBootstrapData")) {
  initialBootstrapData = getFromLocalStorage<BootstrapData>("be.cachedBootstrapData");
}

// make sure initial data is available right away when accessing it via "getBootstrapData()"
queryClient.setQueryData(queryKey, initialBootstrapData);

export function useBackendBootstrapData() {
  return useQuery({
    queryKey: queryKey,
    queryFn: () => fetchBootstrapData(),
    staleTime: Infinity,
    placeholderData: keepPreviousData,
    initialData: initialBootstrapData as BootstrapData,
  });
}

const fetchBootstrapData = async (): Promise<BootstrapData> => {
  return apiClient.get("http://bedesk.test/api/v1/bootstrap-data").then((response) => {
    return decodeBootstrapData(response.data.data);
  });
};

function decodeBootstrapData(data: string | BootstrapData): BootstrapData {
  return typeof data === "string" ? JSON.parse(data) : data;
}
